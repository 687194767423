<template>
  <button :id="id" class="button" :class="getButtonClasses" type="button" :disabled="disabled">
    <UIProgressSpinner
      v-show="loading"
      :id="`${id}-loader`"
      class="button__loader"
      width="25"
      height="25"
      :color="loaderColor"
    />
    <span class="button__content" :class="{ 'button__content--loading': loading }">
      <UIIcon v-if="leftIcon" class="button__left-icon" :name="leftIcon" filled />
      <span class="button__text">
        <slot />
      </span>
      <UIIcon v-if="rightIcon" class="button__right-icon" :name="rightIcon" filled />
    </span>
  </button>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import type { ProgressSpinnerProps } from '~/types/ui'

const props = withDefaults(
  defineProps<{
    variant?: 'primary' | 'secondary' | 'text' | 'ghost' | 'success' | 'error' | 'text-black-100' | 'success-black'
    size?: 'small' | 'default'
    loaderColor?: ProgressSpinnerProps['color']
    disabled?: boolean
    leftIcon?: string
    rightIcon?: string
    fullWidth?: boolean
    loading?: boolean
    id: HTMLAttributes['id']
    compact?: boolean
  }>(),
  {
    variant: 'primary',
    size: 'default',
    loaderColor: 'black',
    disabled: false,
    leftIcon: '',
    rightIcon: '',
    fullWidth: false,
    loading: false,
  },
)

const getButtonClasses = computed(() => [
  ...(props.variant ? [`button--${props.variant}`] : []),
  ...(props.fullWidth ? ['button--full-width'] : []),
  ...(props.disabled ? ['button--disabled'] : []),
  ...(props.size ? [`button--${props.size}`] : []),
  ...(props.loading ? ['button--loading'] : []),
  ...(props.compact ? ['button--compact'] : []),
])
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 22px;
  border: unset;
  position: relative;
  padding: 4px 16px;
  border-radius: $base-border-radius;
  box-shadow: none;
  height: 48px;
  flex-shrink: 0;
  font-size: 16px;
  transition:
    $base-transition background,
    $base-transition color,
    $base-transition border-color,
    $base-transition opacity;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    height: 40px;
  }

  &:focus {
    border: $base-border-width solid $black-100 !important;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $base-transition opacity;
    opacity: 1;
    visibility: visible;

    &--loading {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__loader {
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  > span,
  &__text,
  &__left-icon,
  &__right-icon {
    z-index: 1;
  }

  &:has(.button__left-icon, .button__right-icon):has(.button__text:empty) {
    padding: 12px;
  }

  &:has(.button__left-icon) {
    .button__text:not(:empty) {
      margin-left: 4px;
    }
  }

  &:has(.button__right-icon) {
    .button__text:not(:empty) {
      margin-right: 4px;
    }
  }

  &--compact {
    height: 40px;
  }

  &--small {
    height: 32px;
  }

  &--full-width {
    width: 100%;
  }

  &--primary {
    @include buttonPrimary();
  }

  &--secondary,
  &--text,
  &--ghost {
    @include buttonSecondary();
  }

  &--text,
  &--ghost {
    border: $base-border-width solid transparent;

    &:focus {
      border: $base-border-width solid transparent !important;
    }

    &:hover {
      border: $base-border-width solid transparent;
    }

    &:disabled {
      border: $base-border-width solid transparent !important;
    }
  }

  &--secondary {
    &:disabled {
      background: transparent !important;
    }
  }

  &--ghost {
    padding: 0 !important;
    height: unset;
    border-radius: 4px;
    color: $gold-primary-100;

    &:disabled {
      background: transparent !important;
    }
  }

  &--success {
    background: $success-100;
    border: $base-border-width solid $success-100;
    color: white;
  }

  &--success-black {
    background: $success-100;
    border: $base-border-width solid $success-100;
  }

  &--error {
    background: $error-100;
    border: $base-border-width solid $error-100;
    color: white;
  }

  &--text-black-100 {
    background: $black-100;
    border: $base-border-width solid $black-100;
    color: white;
  }

  &--loading {
    pointer-events: none;
    cursor: default;
  }
}
</style>
